import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppRoutes from './Routes';
import { handleBranding } from './utils';
import usePersistUrlParams from './hooks/usePersistUrlParams'; // Import the hook

import './assets/styles/App.css';
import './assets/styles/Bootstrap.css';

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);

  handleBranding();

  return (
    <Router>
      <PersistUrlParamsWrapper /> {/* Call the URL cleanup inside Router */}
      <AppRoutes />
    </Router>
  );
}

function PersistUrlParamsWrapper() {
  usePersistUrlParams(); // ✅ Now safely inside a Router
  return null; // This component does not render anything
}

export default App;
