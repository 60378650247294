import React from 'react';
import Base from '../Base';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { fetchUserInfo, formatDate } from '../../utils';
import Card from '../../components/elements/Card';
import Image from '../../components/images/Image';
import Icon from '../../components/common/Icon';
import Back from '../../components/common/Back';
import InputField from '../../components/forms/InputField';
import TimeDeltaInputField from '../../components/forms/TimeDeltaInputField';
import CheckboxField from '../../components/forms/CheckboxField';
import TextArea from '../../components/forms/TextArea';
import Button from '../../components/elements/Button';
import { useNavigate } from 'react-router-dom';

const Page = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserInfo('/crew-score', setUserInfo, setLoading, setError, []);
  }, []);

  const onSubmit = async (event) => {
    event.preventDefault();
    
    const formData = new FormData(event.target);
    const values = Object.fromEntries(formData.entries());

    try {
      const response = await fetch('/api/crew-score', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          credentials: 'include',
          body: JSON.stringify(values)
      });
  
      // Check if the response is OK (status code 200-299)
      if (response.ok) {
          const data = await response.json();
          console.log('Success:', data.message);
          setError(null); // Reset error on success
          navigate('/crew-battles'); // Navigate only when successful
      } else {
          // Handle non-200 status codes
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to update score'); // Create an error for the catch block
      }
  
  } catch (error) {
      setError(error.message); // Set error message in state
      setShowToast(true); // Show the toast notification when there's an error
      console.error('Error:', error.message);
  }
  
  };

  return (
    <Base activeNav="home">
      <form onSubmit={onSubmit}>

        <Card>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
              <Image url={userInfo?.teams[0].activity_photo ? userInfo?.teams[0].activity_photo : require('./../../assets/images/fallback/activity.png')} className='circle my-1' size="5rem" />
              <div className='text-center'>
                  <Title my='1' className='' heading='6'>{userInfo?.teams[0].activity_name}</Title>
                  {userInfo?.teams[0].start_time} - {userInfo?.teams[0].end_time}
                  <div>{t('scoringType')} {userInfo?.teams[0].scoring_type}</div>
                  
              </div>
          </div>


            
            
        </Card>
      
        {userInfo?.teams.map((team, index) => (
          <Card key={team.id}>
            <input type="hidden" name={`score_id.${index}`} value={team.score_id} />
            <input type="hidden" name={`challenge_score_id.${index}`} value={team.challenge_score_id} />

            <div className='justify-content-between d-flex align-items-center'>
              <div className='d-flex align-items-center'>
                <div>
                  <Image size="4rem" className='me-2 circle' to="/team" onClick={() => sessionStorage.setItem('otherTeamId', team.team_id)} url={team.team_photo ? team.team_photo : undefined} />
                </div>
                <div className='d-flex flex-column text-start'>
                  <div><b>{team.name}</b></div>
                  <div><Icon name="corporate" style={{ fontSize: '12px' }} /> {team.company_name}</div>
                  <div><Icon name="user-crown" style={{ fontSize: '12px' }} /> {team.captain_name}</div>
                </div>
              </div>
              {team.joker_id && team.joker_id === team.activity_id && (
                <div>
                  <Icon name="star" theme='primary' className='me-1' /> 
                  <b>{t('joker')}</b>
                </div>
              )}
            </div>

            <div className='row mt-2'>
              {team.score_1_label && (
                <InputField 
                  placeholder={team.score_1_label} 
                  label={t("points")} 
                  className="col-6" 
                  value={team.score_1}  
                  name={`score_1.${index}`}
                />
              )}

              {team.score_2_label && (
                <InputField 
                  placeholder={team.score_2_label} 
                  label={t("points")} 
                  className="col-6" 
                  value={team.score_2}  
                  name={`score_2.${index}`}
                />
              )}

              {team.time_1_label && (
                <TimeDeltaInputField 
                  placeholder="MM:SS:SSS"
                  label={t("time")}
                  className="col-6"
                  value={team.time_1}
                  mask="99:99:999"
                  type='text'
                  name={`time_1.${index}`}
                />
              )}

              {team.time_2_label && (
                <TimeDeltaInputField 
                  placeholder="MM:SS:SSS"
                  label={t("time")}
                  className="col-6"
                  value={team.time_2}
                  mask="99:99:999"
                  type='text'
                  name={`time_2.${index}`}
                />
              )}

              <InputField 
                label={t("rank")} 
                placeholder={t('rank')} 
                className="col-6" 
                value={team.rank} 
                name={`rank.${index}`}
              />
            </div>

            <div className='row ms-3'>
              <CheckboxField checked={team.is_ladies} name={`is_ladies.${index}`} label={t("ladies")} className='col-4' mb='0' />
              <CheckboxField checked={team.is_fairplay} name={`is_fairplay.${index}`} label={t("fairplay")} className='col-4' mb='0' />
              <CheckboxField checked={team.is_disqualified} name={`is_disqualified.${index}`} label={t("disqualified")} className='col-4' mb='0' />
              <input type="hidden" name={`is_joker.${index}`} value={team.activity_id === team.joker_id ? 'on' : 'off'} />
            </div>
          </Card>
        ))}

      {/* <TextArea name='comment' label={t('comment')} className='mt-3 px-3' value={userInfo?.teams[0].comment} /> */}
       {/* Bootstrap Toast Notification */}
       <div className="toast-container p-3"  >
          <div className={`toast ${showToast ? 'show' : 'hide'}`} role="alert" aria-live="assertive" aria-atomic="true">
            <div className="toast-header">
              <Icon name="exclamation" theme="primary" className="me-2" />
              <strong className="me-auto">{t(error)}</strong>
              <button type="button" className="btn-close" onClick={() => setShowToast(false)}></button>
            </div>
          </div>
        </div>
      
      <div className='px-3'>
      <Button className='' type='submit'>{t('save')}</Button>
      </div>

      </form>
    </Base>
  );
};

export default Page;