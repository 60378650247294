import React from 'react';
import Screen from '../../components/common/Screen';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import Card from '../../components/elements/Card';
import { useEffect, useState } from 'react';
import { fetchUserInfo, handleImageUpload, handleDeleteImage } from '../../utils';
import Button from '../../components/elements/Button';
import SameLine from '../../components/css/SameLine';
import Image from '../../components/images/Image';
import InputField from '../../components/forms/InputField';
import SelectField from '../../components/forms/SelectField';
import Center from '../../components/css/Center';
import { Link, useNavigate } from 'react-router-dom';
import TextArea from '../../components/forms/TextArea';
import Icon from '../../components/common/Icon';
import ImageInput from '../../components/forms/ImageInput';

const Page = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showToast, setShowToast] = useState(false); 

  useEffect(() => {
    fetchUserInfo('/edit-company', setUserInfo, setLoading, setError, ["otherCompanyId"]);
  }, []);

  const onSubmit = async (event) => {
    event.preventDefault();
    
    const formData = new FormData(event.target);
    const values = Object.fromEntries(formData.entries());

    const otherCompanyId = sessionStorage.getItem('otherCompanyId'); 

    try {
      const response = await fetch('/api/edit-company', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Othercompanyid': otherCompanyId // Include the otherCompanyId in the request headers
          },
          body: JSON.stringify(values)
      });
  
      const data = await response.json();
  
      // Check if the response status is OK (status code 200-299)
      if (response.ok) {
          navigate('/company'); // Navigate only if the request was successful
      } else {
          // Handle non-200 status codes
          console.error('Error:', data.message || 'Failed to edit company');
          throw new Error(data.message || 'Failed to edit company');
      }
  } catch (error) {
      console.error('Error:', error.message); // Log and handle the error
      // Handle your error state or show an error message to the user
      setError(error.message); // Optional: set an error state
      setShowToast(true); // Optional: show a toast notification
  }
  
};

  return (
    <form onSubmit={onSubmit}>
    <Screen color='white' p="3" >
      <div className="top">
        <Title>{t('yourCompany')}</Title>
        
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} className='mb-4'>

        <ImageInput 
          onUpload={(file) => handleImageUpload(file, '/api/upload-company-image', setError, setShowToast)} 
          onDelete={() => handleDeleteImage('/delete-company-image', setError, setShowToast)}
          caption={t('editPicture')} 
          url={userInfo?.photo_url ? userInfo.photo_url : require('./../../assets/images/fallback/company.png')}  
          deleteLabel={t('deletePicture')}
        />

        </div>
        
        {userInfo && (
          <>
            <InputField type="text" name="name" label={t("companyName")} placeholder={t("companyName")} value={userInfo.name} required />
            <InputField type="text" name="personal_website" label={t("website")} placeholder={t("website")} value={userInfo.personal_website} />
            <InputField type="email" name="email" label={t("email")} placeholder={t("email")} value={userInfo.email} />
            <TextArea type="text" name="bio" label={t("bio")} placeholder={t("bio")} value={userInfo.bio} />
            <InputField type="text" name="video_url" label={t("companyVideo")} placeholder="https://www.youtube.com/watch?v=" value={userInfo.video_url} />

            {/* <SelectField name="country" label={t("country")} value={userInfo.country}>
              <option value="" disabled>{t("choose")}</option>
            </SelectField>
            <SelectField name="sector" label={t("sector")} value={userInfo.sector}>
              <option value="" disabled>{t("choose")}</option>
            </SelectField> */}
          </>
        )}

        {/* Bootstrap Toast Notification */}
        <div className="toast-container p-3"  >
          <div className={`toast ${showToast ? 'show' : 'hide'}`} role="alert" aria-live="assertive" aria-atomic="true">
            <div className="toast-header">
              <Icon name="exclamation" theme="primary" className="me-2" />
              <strong className="me-auto">{t(error)}</strong>
              <button type="button" className="btn-close" onClick={() => setShowToast(false)}></button>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom">
        <div className="px-3">
          <hr className="mb-3" />
          <Button type='submit' className='mb-3'>
            {t("done")}
          </Button>
        </div>
      </div>
    </Screen>
    </form>
  );
};

export default Page;