import React, { useEffect, useState } from 'react';
import { fetchUserInfo } from '../../utils';
import Base from '../Base';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import Image from '../../components/images/Image';
import Card from '../../components/elements/Card';
import Left from '../../components/css/Left';
import { Link } from 'react-router-dom';
import Back from '../../components/common/Back';
import Icon from '../../components/common/Icon';
import InputField from '../../components/forms/InputField';
import Center from '../../components/css/Center';

const Participants = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchUserInfo('/participants', setUserInfo, setLoading, setError, ["selectedEventId"]);
  }, []);

  // Function to handle the search input
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase()); // Convert to lowercase for case-insensitive matching
  };

  // Filter the participants based on the search query
  const filteredParticipants = userInfo?.participants?.filter((participant) =>
    participant.full_name.toLowerCase().includes(searchQuery) ||
    (participant.team_name && participant.team_name.toLowerCase().includes(searchQuery))
  );

  return (
    <Base activeNav="home">
      <Card>
        <Back to='/home' />
        <Title my='1' className='mt-3'>{t('participants')}</Title>
        <div className='mb-3'>
          {userInfo && (
            <>
              <Icon name="users" className="mx-1" theme='primary' />
              {userInfo.participants.length} 
            </>
          )}
        </div>
      </Card>

      <div className='px-3 row align-items-center'>
        <div className='col-1' style={{ marginTop: '-0.7rem' }}>
          <Icon name="search" />
        </div>

        <div className='col-11'>
          <InputField 
            placeholder={t('searchParticipants')} 
            name='searchParticipants'
            value={searchQuery} // Bind the input value to the state
            onChange={handleSearchChange} // Update state on input change
          />
        </div>
      </div>

      {filteredParticipants && filteredParticipants.length > 0 ? (
        <div className='row d-flex justify-content-center g-0'>
          {filteredParticipants.map((participant) => (
            <div className='col d-flex justify-content-center mb-3' key={participant.id}>
              <Link
                to="/public-profile"
                onClick={() => sessionStorage.setItem('otherUserId', participant.id)}
                style={{ textDecoration: 'none' }}
              >
                <Image 
                  size="11rem" 
                  url={participant.photo_url ? participant.photo_url : require('./../../assets/images/fallback/user.png')} 
                  className='outline-light'
                  reload
                >
                  <div className="p-2 image-caption">
                    <Title heading="6" my="0">{participant.full_name}</Title> 
                    {participant.team_name}
                  </div>
                </Image>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <Card className="mt-1" p='3'>
          <Center>
            <Image url={require('./../../assets/images/not-found.png')} className='my-4' />
          </Center>
          <Title heading='6' my="0">{t('noParticipantsFound')}</Title>
        </Card>
      )}
    </Base>
  );
};

export default Participants;
