import React from 'react';
import { useEffect, useState } from 'react';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import Screen from '../../components/common/Screen';
import Card from '../../components/elements/Card';
import InputField from '../../components/forms/InputField';
import Button from '../../components/elements/Button';
import { Link } from 'react-router-dom';
import Toast from '../../components/forms/Toast';
import { apiRequest } from '../../utils';


const Page = () => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(null); 
  const [error, setError] = useState(null);
  const [showToast, setShowToast] = useState(false); 

  const onSubmit = async (event) => {
    event.preventDefault();
  
    // Convert form data to a plain object
    const formData = new FormData(event.target);
    const values = Object.fromEntries(formData.entries());
  
    try {
      // Use your global API request function
      const data = await apiRequest("POST", "/forgot-password", values);
      setError(null); // Reset error on success
      setSuccess(data.message); // Set success message from backend
      setShowToast(true); // Show toast notification
    } catch (error) {
      setError(t('somethingWentWrong'));
      setSuccess(null); // Clear success message on error
      setShowToast(true); // Show toast notification when there's an error
    }
  };
  
  return (
    <Screen backgroundImage={localStorage.getItem('backgroundPicture').includes('http') ? localStorage.getItem('backgroundPicture') : (require('./../../assets/images/login.png'))}>
      <div className="center">
        
      <form onSubmit={onSubmit}>
        <Card style={{width: '18rem', opacity: '0.9'}} >
          <Title>{t("forgotPassword")}</Title>
          <p className='my-4'>{t("forgotPasswordInstructions")}</p>
          <InputField type="email" name="email" label={t("email")} placeholder={t("enterEmail")} />
          <Button mb="2" type='submit'>{t("sendEmail")}</Button>
          <div className='mb-3'><Link to="/login" className='link-active'>
            {t("backToLogin")}
          </Link></div>

          <Toast showToast={showToast} setShowToast={setShowToast} message={success ? t(success) : t(error)} icon={success ? "check-circle" : "exclamation"} />
        </Card>
      </form>

      </div>
      
    </Screen>
  );
};

export default Page;