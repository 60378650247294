import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function usePersistUrlParams() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.toString()) {  // If the URL has query params
      params.forEach((value, key) => {
        // Only store safe alphanumeric values (allows dashes and underscores)
        if (/^[a-zA-Z0-9-_.]+$/.test(value)) {
          sessionStorage.setItem(key, value);
        }
      });

      navigate(window.location.pathname, { replace: true }); // Always clean the URL
    }
  }, [location, navigate]);
}

export default usePersistUrlParams;
