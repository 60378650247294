// services.js

import { t } from "i18next";

// Helper function: Convert all sessionStorage key/value pairs into custom headers.
const getSessionHeaders = () => {
  return Object.fromEntries(
    Object.keys(sessionStorage).map(key => [key, sessionStorage.getItem(key)])
  );
};


/**
 * Generic API request function.
 * @param {string} method - The HTTP method ('GET', 'POST', etc.).
 * @param {string} url - The API endpoint (will be prefixed with /api).
 * @param {object|null} body - The request body (for non-GET requests).
 * @param {object} extraHeaders - Any additional headers.
 * @returns {Promise<object>} The JSON response.
 */
export const apiRequest = async (method, url, body = null, extraHeaders = {}) => {
  const fullUrl = `/api${url}`;
  const sessionHeaders = getSessionHeaders();
  
  // Combine headers: use JSON for most requests.
  const headers = {
    'Content-Type': 'application/json',
    ...sessionHeaders,
    ...extraHeaders,
  };

  const options = {
    method: method.toUpperCase(),
    headers,
    credentials: 'include', // Send cookies (e.g., JWT)
  };

  if (body && method.toUpperCase() !== 'GET') {
    options.body = JSON.stringify(body);
  }

  const response = await fetch(fullUrl, options);
  if (!response.ok) {
    let errorMessage = 'Something went wrong';
    try {
      // Attempt to extract the error message from the response body
      const errorData = await response.json();
      errorMessage = errorData.message || errorMessage;
    } catch (err) {
      // In case parsing fails, the generic error message will be used.
    }
    throw new Error(errorMessage);
  }
  return response.json();
};

/**
 * Special API function for submitting FormData.
 * Use this when you need to send form data (e.g., file uploads).
 */
export const apiFormRequest = async (url, formData, extraHeaders = {}) => {
  const fullUrl = `/api${url}`;
  const sessionHeaders = getSessionHeaders();
  // Don't set Content-Type here; let fetch set it automatically with FormData
  const headers = {
    ...sessionHeaders,
    ...extraHeaders,
  };

  const response = await fetch(fullUrl, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: formData,
  });

  if (!response.ok) {
    throw new Error('Something went wrong');
  }
  return response.json();
};


export const fetchUserInfo = async (url, setUserInfo, setLoading, setError) => {
  try {
    const data = await apiRequest('GET', url);
    setUserInfo(data);
    setLoading(false);
  } catch (err) {
    setError(err.message);
    setLoading(false);
  }
};



export const formatDate = (dateString) => {
  if (!dateString) return 'Invalid date';

  const date = new Date(dateString);
  if (isNaN(date.getTime())) return 'Invalid date';

  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Intl.DateTimeFormat('en-US', options).format(date);
};


export const logout = async () => {
  // Make a request to the backend to clear the JWT cookie
  await fetch('/api/logout', {
    method: 'POST',
    credentials: 'include'  // Ensure cookies are sent with the request
  });

  // Clear sessionStorage
  sessionStorage.clear();

  // Redirect to login page
  window.location.href = '/login';
};



export const handleImageUpload = async (file, uploadUrl, setError, setShowToast) => {
  const formData = new FormData();
  formData.append('file', file); // Append the image file

  try {
    const response = await fetch(uploadUrl, {
      method: 'POST',
      headers: {
        'Othercompanyid': sessionStorage.getItem('otherCompanyId'),
        'Otherteamid': sessionStorage.getItem('otherTeamId'),
      },
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
      window.location.reload(); // Reload the window after successful upload
    } else {
      const errorData = await response.json();
      throw new Error(errorData.message || t('somethingWentWrong')); // Create an error for the catch block
    }
  } catch (error) {
    setError(t('somethingWentWrong')); // Set error message in state
    setShowToast(true); // Show the toast notification when there's an error
  }
};


export const handleDeleteImage = async (deleteUrl, setError, setShowToast) => {
      const confirmed = window.confirm(t('areYouSureDeleteImage'));
      if (!confirmed) return;
    
      try {
        await apiRequest('DELETE', deleteUrl);
        setError(null);
        window.location.reload();
      } catch (error) {
        setError(error.message);
        setShowToast(true);
      }
    };


export function applyStoredBrandingColors() {
  const savedPrimaryColor = localStorage.getItem("primaryColor") || "#FF784B"; 
  const savedSecondaryColor = localStorage.getItem("secondaryColor") || "#002335"; 

  document.documentElement.style.setProperty("--primary-color", savedPrimaryColor);
  document.documentElement.style.setProperty("--secondary-color", savedSecondaryColor);
}

export function setFavicon() {
  const savedFavicon = localStorage.getItem("favicon") || `${window.location.origin}/favicon.ico`;

  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
  }
  link.href = savedFavicon;
}

export async function handleBranding() {
  const currentDomain = window.location.hostname;  // Get current domain
  const storedDomain = localStorage.getItem("brandingDomain");

  if (storedDomain !== currentDomain) {
      try {
          const response = await fetch(`/api/branding`); // Fetch branding from API
          if (!response.ok) throw new Error("Branding fetch failed");
          const branding = await response.json();

          // Save new branding colors
          localStorage.setItem("primaryColor", branding.primary_color || "#FF784B");
          localStorage.setItem("secondaryColor", branding.secondary_color || "#002335");

          localStorage.setItem("favicon", branding.fav_icon || `${window.location.origin}/favicon.ico`);

          localStorage.setItem("backgroundPicture", branding.share_picture);

          // Save current domain to avoid re-fetching unnecessarily
          localStorage.setItem("brandingDomain", currentDomain);

          // Apply new branding colors
          applyStoredBrandingColors();
          setFavicon();

      } catch (error) {
          console.error("Failed to fetch branding:", error);
      }
  } else {
      applyStoredBrandingColors();  // Apply stored branding if domain hasn't changed
      setFavicon();
  }
}

