import React from 'react';
import Button from './Button';
import { t } from 'i18next';

const Modal = ({ show, title, children, onClose, onSubmit }) => {
  if (!show) return null;
  return (
    <div className="modal fade show d-block" tabIndex="-1" role="dialog" aria-modal="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button type="button" className="btn-close" onClick={onClose}></button>
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer">
            <Button onClick={onClose} theme="secondary" styles={{}} mb='0'>{t('cancel')}</Button>

            <Button onClick={onSubmit} styles={{}} mb='0'>{t('save')}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
