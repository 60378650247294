import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchUserInfo } from '../../utils';
import Screen from '../../components/common/Screen';
import Card from '../../components/elements/Card';
import Title from '../../components/common/Title';
import NavBar from '../../components/common/NavBar';
import Icon from '../../components/common/Icon';
import { Link } from 'react-router-dom';
import Image from '../../components/images/Image';
import Button from '../../components/elements/Button';
import { logout } from '../../utils';


const Profile = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/profile', setUserInfo, setLoading, setError);
  }, []);

  return (
    <>
    <Screen>
      <div className="top">
        

        <Card>
          {/* <Link to="/settings" style={{ textDecoration: 'none' }}>
            <div className='text-end active'>
              <Icon name="settings" pack="sr" className="m-2" />
            </div>
          </Link> */}
        
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Image url={userInfo?.picture ? userInfo.picture : undefined} className={'my-3 circle'} reload={true} />
          </div>
        
          <Title my="0" heading='5'>
            {loading ? <p className="placeholder-glow"><span className="placeholder col-6"></span></p> : userInfo.full_name}
          </Title>
        
          <p className="text-secondary">
            {loading ? <p className="placeholder-glow"><span className="placeholder col-4"></span></p> : userInfo.email}
          </p>
        </Card>


        


        <div className='px-3'>
          
        <Button theme='light' p="3" to="/role">
              <div className="d-flex justify-content-between">
                <div className="text-start">
                  <Icon name="shuffle" className="me-2" />
                  <b>{t("selectYourRole")}</b>
                </div>
                <div className="text-end">
                  <Icon name="angle-right" />
                </div>
              </div>
          </Button>


          {userInfo?.is_public && (
            <Button theme='light' p="3" to="/public-profile" onClick={() => sessionStorage.setItem('otherUserId', userInfo.id)}>
              <div className="d-flex justify-content-between">
                <div className="text-start">
                  <Icon name="eye" className="me-2" />
                  <b>{t("publicProfile")}</b>
                </div>
                <div className="text-end">
                  <Icon name="angle-right" />
                </div>
              </div>
            </Button>
          )}

          <Button theme='light' p="3" to="/edit-profile">
              <div className="d-flex justify-content-between">
                <div className="text-start">
                  <Icon name="user-pen" className="me-2" />
                  <b>{t("editProfile")}</b>
                </div>
                <div className="text-end">
                  <Icon name="angle-right" />
                </div>
              </div>
          </Button>
          
          <Button theme='light' p="3" to="/language">
              <div className="d-flex justify-content-between">
                <div className="text-start">
                  <Icon name="language-exchange" className="me-2" />
                  <b>{t("language")}</b>
                </div>
                <div className="text-end">
                  <Icon name="angle-right" />
                </div>
              </div>
          </Button>

          <Button theme='light' p="3" to="/connections">
              <div className="d-flex justify-content-between">
                <div className="text-start">
                  <Icon name="facebook" className="me-2" pack='brands' />
                  <b>{t("socials")}</b>
                </div>
                <div className="text-end">
                  <Icon name="angle-right" />
                </div>
              </div>
          </Button>

          <Button theme='light' p="3" to="mailto:info@herculestrophy.com">
            <div className="d-flex justify-content-between">
              <div className="text-start">
                <Icon name="customer-service" className="me-2" />
                <b>{t("contactUs")}</b>
              </div>
              <div className="text-end">
                <Icon name="angle-right" />
              </div>
            </div>
          </Button>



          <Button theme="outline-primary" p="3" to="/login" onClick={logout}>
              <Icon name="sign-out-alt" className="me-2" theme='primary' />
              <b>{t("logout")}</b>
          </Button>
        </div>



        {/* <Card to="/wallet">
          <Title my="2" align="start">{t("wallet")}</Title>
        </Card>
        
        <Card to="/badges">
          <Title my="2" align="start">{t("badges")}</Title>
        </Card> */}
        
      </div>

      <div className="bottom">
        <NavBar active="profile" />
      </div>
    </Screen>
    </>
  );
};

export default Profile;


