import React from 'react';
import Base from '../Base';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import Card from '../../components/elements/Card';
import Left from '../../components/css/Left';
import { useEffect, useState } from 'react';
import { fetchUserInfo } from '../../utils';
import CoverImage from '../../components/images/CoverImage';
import Icon from '../../components/common/Icon';
import { Link } from 'react-router-dom';
import Image from '../../components/images/Image';
import Center from '../../components/css/Center';
import Button from '../../components/elements/Button';

const Page = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/company', setUserInfo, setLoading, setError, ['otherCompanyId']);
  }, []);

  return (
    <Base activeNav="home">
        
        {userInfo?.cover_image && <CoverImage image={userInfo?.company.cover_image} mb="0" />}
        <Card>
            <Center><Image url={userInfo?.company.photo_url ? userInfo.company.photo_url : require('./../../assets/images/fallback/company.png')} className='circle contain-image' contain reload /></Center>

            <Title my="4">
                {userInfo?.company.name}
                {userInfo?.company.personal_website &&
                    <Link to={userInfo?.company.personal_website} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <Icon 
                            name="arrow-up-right-from-square" 
                            style={{ color: userInfo?.company.primary_color ? userInfo?.company.primary_color : 'black', marginLeft: '0.3rem' }} 
                        />
                    </Link>
                }
            </Title>
            

            <div className='row mb-3'>
                <div className='col-4'>
                    <Title heading="6" my="2">
                        {userInfo?.company.event_count} <br />
                        {t('events')}
                    </Title>
                </div>
                <div className='col-4'>
                    <Title heading="6" my="2">
                        {userInfo?.company.team_count} <br />
                        {t('teams')}
                    </Title>
                </div>
                <div className='col-4'>
                    <Title heading="6" my="2">
                        {userInfo?.company.employee_count} <br />
                        {t('players')}
                    </Title>
                </div>
            </div>

            <p className='text-secondary'>{userInfo?.company.bio}</p>
                {userInfo?.company.video_url && 
                    <Button 
                    styles={{ color : userInfo?.company.primary_color ? userInfo?.company.primary_color : 'inherit' }} 
                    theme='light' 
                    to={`${userInfo?.company.video_url}`}
                    className='mx-1'
                    >
                    <Icon name="youtube" pack='brands' />
                    </Button>
                }
            
            {userInfo?.role === "contactPerson" && sessionStorage.getItem('otherCompanyId') === userInfo?.user_company_id && (
                <>
                {/* SETTINGS */}
                <Button styles={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }} theme='light' to="/edit-company" className='mx-1'>
                <Icon name="settings" theme='primary' pack='sr' />
                </Button>
                
                {/* MANAGE EMPLOYEES */}
                <Button to="/manage-employees" styles={{}}>
                    <Icon name="users-gear" className="me-1" /> {t('manageEmployees')}
                </Button>
                </>
            )}

            
        </Card>

        
        

        <Card>
            <Left><Title my="0" className={'mb-3 mt-1'}>{t('teams')}</Title></Left>

            <div className='row d-flex justify-content-center g-0'>
                {userInfo?.teams.map((team) => (
                <div className='col d-flex justify-content-center mb-3'>
                    <Link to="/team" onClick={() => sessionStorage.setItem('otherTeamId', team.id)} style={{ textDecoration: 'none' }}>
                        <Image size="9.5rem" url={team.photo_url ? team.photo_url : require('./../../assets/images/fallback/team.png')} reload={true}>
                        <Center>
                            <div className="image-top px-3 py-1">
                              <b>
                                <Icon name="calendar" pack='sr' className="me-2" theme='light' />
                                {new Date(team.date).getFullYear()}

                              </b>
                            </div>
                          </Center>

                        <div className="p-2 image-caption">
                            <Title heading="6" my="0">{team.name}</Title> 
                        </div>
                        </Image>
                    </Link>
                </div>
                ))}
            </div>
        </Card>

        {/* <Card>
            <Left><Title>{t('badges')}</Title></Left>
        </Card> */}
    </Base>
  );
};

export default Page;