import React, { useEffect, useState } from 'react';
import Screen from '../../components/common/Screen';
import Title from '../../components/common/Title';
import Card from '../../components/elements/Card';
import Image from '../../components/images/Image';
import Button from '../../components/elements/Button';
import { useTranslation } from 'react-i18next';
import { fetchUserInfo, formatDate } from '../../utils';
import { logout } from '../../utils';
import Icon from '../../components/common/Icon';
import Back from '../../components/common/Back';
import Center from '../../components/css/Center';

const Page = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/event-select', setUserInfo, setLoading, setError);
  }, []);


  const handleEventSelect = async (eventId) => {
    try {
      // Send POST request with the selected event_id
      const response = await fetch('/api/event-select', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          credentials: 'include',
        },
        body: JSON.stringify({ event_id: eventId })
      });
  
      if (!response.ok) {
        throw new Error('Failed to select event');
      }
  
      const data = await response.json();
      console.log('Event selected:', data);
  
      if (userInfo?.role === 'supporter' || userInfo?.role === 'contactPerson') {
        window.location.href = '/home';
      } else {
        window.location.href = '/battle-select';
      }
    } catch (error) {
      console.error('Error selecting event:', error);
    }
  };

  return (
    <Screen>
      <div className='top'>

        


        <Card>

          <Back to="/role" />
          <Title my="4">{t('selectYourEvent')}</Title>

          <Button 
              theme="outline-primary" 
              p="2" 
              to="/login" 
              styles={{ position: 'absolute', right: '1.3rem', top: '1.7rem', backgroundColor: 'inherit' }} 
              onClick={logout}
          >
              <Icon name="sign-out-alt" theme='primary' />
          </Button>
        </Card>

        
        {userInfo?.events?.length > 0 ? (
          userInfo.events.map((event) => (
              <Card key={event.id} onClick={() => handleEventSelect(event.id)} style={{ cursor: 'pointer' }}>
                  <div className='justify-content-between d-flex align-items-center'>
                      <div className='d-flex align-items-center'>
                          <div>
                              <Image size="5rem" className='me-3' url={event.image_link ? event.image_link : require('./../../assets/images/fallback/event.png')} />
                          </div>
                          <div className='d-flex flex-column text-start'>
                              <div><b>{event.name} </b></div>
                              <div className='text-secondary'>{formatDate(event.date)} </div>
                          </div>
                      </div>
                  </div>
              </Card>
          ))
      ) : (
        <Card className="mt-1" p='3'>
          <Center><Image url={require('./../../assets/images/not-found.png')} className='my-4' /></Center>
          <Title heading='6' my="0">{t('noEventsFound')}</Title>
        </Card>
      )}



      </div>
    </Screen>
  );
};

export default Page;