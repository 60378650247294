import React from 'react';
import Base from '../Base';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import { fetchUserInfo } from '../../utils';
import { useEffect, useState } from 'react';
import Card from '../../components/elements/Card';
import Button from '../../components/elements/Button';
import Icon from '../../components/common/Icon';
import notificationConfig from '../../config/notificationConfig.json'; 


const Page = () => {
  const { t, i18n } = useTranslation(["common", "notifications"]);

  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/notifications', setUserInfo, setLoading, setError);
  }, []);

  // Helper to replace placeholders in redirectUrl
  const replaceUrlPlaceholders = (url, data) => {
    return url.replace(/{{(\w+)}}/g, (_, key) => data[key] || key);
  };

  const getNotificationDetails = (messageName, rawData = {}) => {
    // 1. Fetch the translatable text from i18n
    const messageTemplate = `notifications:${messageName}`;
    
    // 2. Fetch metadata (icon, redirectUrl, dynamic key mappings)
    const meta = notificationConfig[messageName] || {};
    const icon = meta.icon || 'exclamation-circle';
    let redirectUrl = meta.redirectUrl || '#';

    // 3. Convert `dynamic_key_1` and `dynamic_key_2` to meaningful names
    const dynamicData = {};
    if (meta.dynamicKey1 && rawData.dynamic_key_1) {
      dynamicData[meta.dynamicKey1] = rawData.dynamic_key_1;
    }
    if (meta.dynamicKey2 && rawData.dynamic_key_2) {
      dynamicData[meta.dynamicKey2] = rawData.dynamic_key_2;
    }

    if (rawData.redirect_key_1) {
      dynamicData["redirect_key_1"] = rawData.redirect_key_1;
    }

    // 4. Replace placeholders in message & URL
    const message = t(messageTemplate, dynamicData);
    redirectUrl = replaceUrlPlaceholders(redirectUrl, dynamicData);

    return { message, icon, redirectUrl };
  };



  return (
    <Base activeNav="notifications">
      <Title>{t('notifications')}</Title>

      {userInfo?.notifications?.length > 0 ? (
        <div className="px-2">
          {userInfo.notifications.map((notification) => {
            // e.g. notification.message_name = 'team_challenged'
            //      notification.dynamic_data = { team_name: 'Team ABC', event_name: 'Battle 2025', teamId: 'XYZ123' }
            const details = getNotificationDetails(
              notification.message_name, 
              notification
            );

            return (
              <Button 
                key={notification.id}          // or any unique identifier
                theme={notification.is_read ? "light" : "primary"}
                p="3"
                to={details.redirectUrl}
                mb='2'
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="text-start">
                    <Icon name={details.icon} className="me-3" theme={notification.is_read ? "primary" : "light"} />
                    
                  </div>
                  <div className='text-start' style={{ lineHeight: "1.5" }}>
                    {details.message}
                  </div>
                  <div className="text-end">
                    <Icon name="angle-right" />
                  </div>
                </div>
              </Button>
            );
          })}
        </div>
      ) : (
        <p>{t('noNotificationsFound')}</p>
      )}
    </Base>
  );
};

export default Page;