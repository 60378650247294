import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../../components/elements/Card';
import InputField from '../../components/forms/InputField';
import Button from '../../components/elements/Button';
import Screen from '../../components/common/Screen';
import Title from '../../components/common/Title';
import { Link, useNavigate } from 'react-router-dom';
import Toast from '../../components/forms/Toast';
import Image from '../../components/images/Image';
import Icon from '../../components/common/Icon';
import { fetchUserInfo, apiRequest } from '../../utils';

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [showToast, setShowToast] = useState(false); 
  const [showPassword, setShowPassword] = useState(false); 
  

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch('/api/check-auth', {
          method: 'GET',
          credentials: 'include'
        });
        const data = await response.json();
        
        // Redirect only if the message indicates the user is authenticated
        if (data.message === 'Authenticated') {
          navigate('/role');
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
      }
    };
  
    checkAuth();
  }, [navigate]);
  
  const initialValues = {
    email: '',
    password: '',
  };

  const onSubmit = async (event) => {
    event.preventDefault();
  
    // Convert form data to a plain object
    const formData = new FormData(event.target);
    const values = Object.fromEntries(formData.entries());
  
    try {
      // Use the global API wrapper, which automatically includes sessionStorage data
      const data = await apiRequest('POST', '/login', values); 
      setError(null); // Reset error on success
      sessionStorage.removeItem('assignedEventId');
      sessionStorage.removeItem('assignedTeamId');
      sessionStorage.removeItem('assignedCompanyId');

      sessionStorage.getItem('redirect') ? navigate(sessionStorage.getItem('redirect')) : navigate('/role');
    } catch (error) {
      setError(error.message); // Set error message in state
      setShowToast(true); // Show toast notification when there's an error
    }
  };
  

  return (
    <Screen backgroundImage={localStorage.getItem('backgroundPicture').includes('http') ? localStorage.getItem('backgroundPicture') : (require('./../../assets/images/login.png'))}>
      <div className="center">
        <Card style={{width: '18rem', opacity: '0.9'}} mx='0'>
          <Title>{t("login")}</Title>
              <form onSubmit={onSubmit}>
                <InputField type="text" name="email" label={t("email")} placeholder={t("enterEmail")} required />

                <div style={{ position: 'relative' }}>
                  <InputField type={showPassword ? "text" : "password"} name="password" mb="1" label={t("password")} placeholder={t("enterPassword")} required />
                  
                  {/* Eye Icon */}
                  <span
                    onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '50%',
                      cursor: 'pointer'
                    }}
                  >
                    {showPassword ? <Icon name="eye-crossed" theme='primary' /> : <Icon name="eye" theme='primary' />}
                  </span>
                </div>

                <div className='text-start mb-3'><Link to="/forgot-password" className='link-active'>
                  {t("forgotPassword")}
                </Link></div>
                <Button mb="2" type="submit" >{t("login")}</Button>
              </form>
    
          <Button theme='secondary' to='/register' >{t("register")}</Button>

          <Toast showToast={showToast} setShowToast={setShowToast} message={t(error)} />
        </Card>
        </div>

      <div className='bottom' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '-1rem' }}>
          <Card style={{opacity: '0.8'}} p='0'>
            <Image url={require('./../../assets/images/logo_text.png')} to={'https://tornea.net'} className='mx-5' isSquare={false} style={{width: '8rem', height: '4rem'}} />
            <div className='text-secondary'>Alpha v1.0</div>
          </Card>
      </div>
    </Screen>
  );
  

  
};


export default Login;


