import React from 'react';
import Base from '../Base';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { fetchUserInfo } from '../../utils';
import Card from '../../components/elements/Card';
import Image from '../../components/images/Image';
import Icon from '../../components/common/Icon';
import Left from '../../components/css/Left';
import { Link } from 'react-router-dom';
import Button from '../../components/elements/Button';
import Center from '../../components/css/Center';


const Page = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/rivals', setUserInfo, setLoading, setError, ["rivalsId"]);
  }, []);

  return (  
  <Base activeNav="home">
    <div style={{position: 'absolute'}} className='p-2' >
      <Link to='/team' style={{color: 'inherit', textDecoration: 'none'}}>
        <Icon name='angle-left' />
      </Link>
    </div>

    <Title heading='1'>{t('rivals')}</Title>

    {userInfo && (
      <>
        
        {userInfo?.role === 'player' && (userInfo.challenged.length > 0 || userInfo.challengers.length > 0) && (
              <Button theme='secondary' className='px-3' to='/teams' styles={{}} ><Icon name='search' theme='light' className={'me-1'} /> {t('findTeamChallenge')} </Button>
        )}

        {userInfo.challenged && userInfo.challenged.length > 0 && (
          <div className='row d-flex justify-content-center g-0 p-2'>
            <Left className='px-2'><Title heading="5" my="0" className='mb-3'>
              {t('challenged')}  
              ({userInfo.challenged.length}/{userInfo.challenged[0].max_challenge_others})
            </Title></Left>

            {userInfo.challenged.map((team) => (
              <Card 
                key={team.id}
                to= "/team"
                onClick= {() => sessionStorage.setItem('otherTeamId', team.id)}
              >
                <div className='justify-content-between d-flex align-items-center'>
                  <div className='d-flex align-items-center'>
                    <div>
                      <Image size="3rem" className='me-2' url={team.photo_url ? team.photo_url : require('./../../assets/images/fallback/team.png')} reload={true} />
                    </div>
                    <div className='d-flex flex-column text-start'>
                      <div><b>{team.name}</b></div>
                      <div className='text-secondary'>{team.company_name} </div>
                    </div>
                  </div>
                  <b><Icon name="angle-right" /></b>
                </div>
              </Card>
            ))}
          </div>
        )}
    
        {userInfo.challengers && userInfo.challengers.length > 0 && (
          <div className='row d-flex justify-content-center g-0 p-2'>
            <Left className='px-2'><Title heading="5" my="0" className='mb-3'>{t('challengedBy')}</Title></Left>
            {userInfo.challengers.map((team) => (
              <Card 
                key={team.id}
                to= "/team"
                onClick= {() => sessionStorage.setItem('otherTeamId', team.id)}
              >
                <div className='justify-content-between d-flex align-items-center'>
                  <div className='d-flex align-items-center'>
                    <div>
                      <Image size="3rem" className='me-2' url={team.photo_url ? team.photo_url : require('./../../assets/images/fallback/team.png')} reload={true} />
                    </div>
                    <div className='d-flex flex-column text-start'>
                      <div><b>{team.name}</b></div>
                      <div className='text-secondary'>{team.company_name} </div>
                    </div>
                  </div>
                  <b><Icon name="angle-right" /></b>
                </div>
              </Card>
            ))}
          </div>
        )}

    
        {(userInfo.challenged.length === 0) && 
         (userInfo.challengers.length === 0) && (
          <Card p='3'>
            <Center><Image url={require('./../../assets/images/not-found.png')} className='my-4' /></Center>
            <Title heading="4" my="2" className='mb-3'>{t('noRivalsYet')} </Title>
            <Button mb='2' to='/team' >{t('backToTeam')} </Button>
            <Button theme='secondary' to='/teams' ><Icon name='search' theme='light' className={'me-1'} /> {t('findTeamChallenge')} </Button>

          </Card>
        )}
      </>
    )}

</Base>
  );
};

export default Page;