import React, { useState, useRef, useEffect } from 'react';
import Image from '../images/Image';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ImageInput = ({ onUpload, onDelete, url, caption, deleteLabel }) => {
  const [imageSrc, setImageSrc] = useState(url); // Initially use the provided image URL
  const fileInputRef = useRef(null); // To reference the hidden file input

  // Update the image preview when the URL changes
  useEffect(() => {
    if (url) {
      setImageSrc(url); // Update the image preview with the new URL passed as a prop
    }
  }, [url]); // Re-run this effect whenever the `url` prop changes

  // Handle file input change and trigger upload
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImageSrc(reader.result); // Update image preview locally
      };
      onUpload(file); // Trigger the external `onUpload` function to handle the upload
    }
  };

  // Trigger file input when the user clicks the link
  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {/* Image */}
      <Image 
        size='5rem' 
        className='mb-2 circle' 
        url={imageSrc || require('./../../assets/images/fallback/user.png')}
        reload={true}
      />
  
      {/* Hidden file input */}
      <input 
        type="file" 
        accept="image/*" 
        ref={fileInputRef} 
        onChange={handleFileChange} 
        style={{ display: 'none' }}
      />
    
      {/* Links container */}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {/* Edit link */}
        <Link 
          to="#" 
          style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }} 
          onClick={triggerFileInput}
        >
          <b>{caption}</b>
        </Link>
  
        {onDelete && (
          <>
            <span style={{ margin: '0 0.5rem', color: '#ccc' }}>•</span>
            <a 
              onClick={(e) => {
                e.preventDefault();
                onDelete();
              }} 
              className='text-danger'
              style={{ textDecoration: 'none', cursor: 'pointer', fontWeight: 'bold' }} 
            >
              {deleteLabel}
            </a>
          </>
        )}
      </div>
    </div>
  );
};

export default ImageInput;
