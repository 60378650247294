import React from 'react';
import Base from '../Base';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import Image from '../../components/images/Image';
import Card from '../../components/elements/Card';
import Center from '../../components/css/Center';
import CoverImage from '../../components/images/CoverImage';
import { useEffect, useState } from 'react';
import { fetchUserInfo } from '../../utils';
import { formatDate } from '../../utils';
import { Link } from 'react-router-dom';



const Page = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/event', setUserInfo, setLoading, setError, ["selectedEventId"]);
  }, []);

  return (
    <Base activeNav="home">
        {userInfo && <CoverImage image={userInfo.image_link} />}

        <Card className='mt-1'>

            <Center><Image url={userInfo?.photo_url ? userInfo.photo_url : require('./../../assets/images/fallback/event.png')} className="circle" size='5rem' contain /></Center>
            <Title heading='5' my="0">
                {loading ? <p className="placeholder-glow"><span className="placeholder col-6"></span></p> : userInfo.name}
            </Title>
            <p>
                {loading ? <p className="placeholder-glow"><span className="placeholder col-4"></span></p> : formatDate(userInfo.date)}
            </p>
            <p>
                {loading ? <p className="placeholder-glow"><span className="placeholder col-4"></span></p> : userInfo.location_name}
            </p>

            <p className='text-secondary'>
                {loading ? <p className="placeholder-glow"><span className="placeholder col-10"></span></p> : <span dangerouslySetInnerHTML={{ __html: userInfo.short_desc }} />}
            </p>

        </Card>

        {userInfo && userInfo.long_desc && (
          <Card>
            <Title heading='5' my="2">{t('description')}</Title>
            {loading ? (
              <p className="placeholder-glow">
                <span className="placeholder col-10"></span>
              </p>
            ) : (
              <span dangerouslySetInnerHTML={{ __html: userInfo.long_desc }} />
            )}
          </Card>
        )}
        
        {userInfo && userInfo.planning && userInfo.planning.length > 0 && (
          <Card>
            <Title heading='5' my="2">{t('planning')}</Title>
            {userInfo.planning.map((activity, index) => (
              <div
                key={index}
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                className='mb-1'
              >
                <div className='text-secondary'>{activity.name}</div>
                <div className='text-secondary'>{activity.start_time}</div>
              </div>
            ))}
          </Card>
        )}

        


    </Base>
  );
};

export default Page;