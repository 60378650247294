import React from 'react';
import { Route, Routes as RouterRoutes } from 'react-router-dom';
import NotFound from './pages/NotFound';
import {
  Login,
  ForgotPassword,
  Register,
  TeamSelect,
  Role,
  EventSelect,
  ResetPassword,
  VerifyAccount,
  BattleSelect
} from './pages/main';
import {
  LanguageSelector,
  Profile,
  Settings,
  Badges,
  PublicProfile,
  Connections,
  EditProfile,
  Wallet,
} from './pages/user';
import { Feed, Notifications } from './pages/feed';
import {
  Home,
  Teams,
  Rules,
  BrainChallenge,
  PictureChallenge,
  Awards,
  Companies,
  FAQ,
  Houses,
  Battles,
  Battle,
  Leaderboard,
  House,
  Map,
  Event,
  Scores,
  Score,
  CrewBattles,
  CrewScore,
  Participants
} from './pages/event';
import {
  Cheers,
  EditTeam,
  Rivals,
  Planning,
  Team,
  Players,
  AddPlayers
} from './pages/team';
import { 
  EditCompany, 
  ManageEmployees, 
  Company 
} from './pages/company';


const AppRoutes = () => (
  <RouterRoutes>
    <Route path="/login" element={<Login />} />
    <Route path="/" element={<Login />} />
    <Route path="/forgot-password" element={<ForgotPassword />} />
    <Route path="/register" element={<Register />} />
    <Route path="/team-select" element={<TeamSelect />} />
    <Route path="/role" element={<Role />} />
    <Route path="/event-select" element={<EventSelect />} />
    <Route path="/reset-password" element={<ResetPassword />} />
    <Route path="/confirm-email" element={<VerifyAccount />} />
    <Route path="/battle-select" element={<BattleSelect />} />

    {/* EVENT */}
    <Route path="/home" element={<Home />} />
    <Route path="/teams" element={<Teams />} />
    {/* <Route path="/rules" element={<Rules />} /> */}
    {/* <Route path="/brain-challenge" element={<BrainChallenge />} />
    <Route path="/picture-challenge" element={<PictureChallenge />} /> */}
    <Route path="/awards" element={<Awards />} />
    <Route path="/companies" element={<Companies />} />
    {/* <Route path="/faq" element={<FAQ />} /> */}
    <Route path="/houses" element={<Houses />} />
    <Route path="/battles" element={<Battles />} />
    <Route path="/battle" element={<Battle />} />
    <Route path="/leaderboard" element={<Leaderboard />} />
    <Route path="/house" element={<House />} />
    {/* <Route path="/map" element={<Map />} /> */}
    <Route path="/event" element={<Event />} />
    <Route path="/scores" element={<Scores />} />
    <Route path="/score" element={<Score />} />
    <Route path="/crew-battles" element={<CrewBattles />} />
    <Route path="/crew-score" element={<CrewScore />} />
    <Route path="/participants" element={<Participants />} />

    {/* USER */}
    <Route path="/language" element={<LanguageSelector />} />
    <Route path="/profile" element={<Profile />} />
    {/* <Route path='/settings' element={<Settings />} /> */}
    <Route path='/badges' element={<Badges />} />
    <Route path='/public-profile' element={<PublicProfile />} />
    <Route path='/connections' element={<Connections />} />
    <Route path='/edit-profile' element={<EditProfile />} />
    {/* <Route path='/wallet' element={<Wallet />} /> */}

    {/* TEAM */}
    <Route path="/cheers" element={<Cheers />} />
    <Route path="/edit-team" element={<EditTeam />} />
    <Route path="/rivals" element={<Rivals />} />
    <Route path="/team" element={<Team />} />
    <Route path="/planning" element={<Planning />} />
    <Route path="/players" element={<Players />} />
    <Route path="/add-players" element={<AddPlayers />} />

    {/* FEED */}
    {/* <Route path="/feed" element={<Feed />} /> */}
    <Route path="/notifications" element={<Notifications />} />

    {/* COMPANY */}
    <Route path="/company" element={<Company />} />
    <Route path="/edit-company" element={<EditCompany />} />
    <Route path="/manage-employees" element={<ManageEmployees />} />

    {/* 404 - Catch-All Route */}
    <Route path="*" element={<NotFound />} />  {/* Catch-all route for 404 */}

  </RouterRoutes>
);

export default AppRoutes;