import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InputField from '../../components/forms/InputField';
import Button from '../../components/elements/Button';
import Toast from '../../components/forms/Toast';
import Screen from '../../components/common/Screen';
import Card from '../../components/elements/Card';
import Title from '../../components/common/Title';
import Icon from '../../components/common/Icon';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { apiRequest } from '../../utils';

const RegisterPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch('/api/check-auth', {
          method: 'GET',
          credentials: 'include'
        });
        const data = await response.json();
        
        // Redirect only if the message indicates the user is authenticated
        if (data.message === 'Authenticated') {
          navigate('/role');
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
      }
    };
  
    checkAuth();
  }, [navigate]);
  
  // Extract event_id from URL query parameters
  const urlParams = new URLSearchParams(window.location.search);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [error, setError] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const MIN_PASSWORD_LENGTH = 8;
  const MAX_PASSWORD_LENGTH = 20;
  const PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{}|;':",.<>?/\\])[A-Za-z\d!@#$%^&*()_+\-=\[\]{}|;':",.<>?/\\]{8,20}$/;

  const handleRegister = async (event) => {
    event.preventDefault();
  
    // Validate that the passwords match
    if (password !== confirmPassword) {
      setError(t('passwordMismatchError', 'Passwords do not match'));
      setShowToast(true);
      return;
    }
  
    // Validate password length
    if (password.length < MIN_PASSWORD_LENGTH || password.length > MAX_PASSWORD_LENGTH) {
      setError(t('passwordLengthError', { min: MIN_PASSWORD_LENGTH, max: MAX_PASSWORD_LENGTH }));
      setShowToast(true);
      return;
    }
  
    // Validate password pattern
    if (!PASSWORD_PATTERN.test(password)) {
      setError(t('passwordPatternError'));
      setShowToast(true);
      return;
    }
  
    // Optionally, additional validations (e.g., valid email) can be added here
  
    try {
      // Use the global API request function to send the registration data.
      const data = await apiRequest('POST', '/register', { firstName, lastName, email, password });
      setSuccessMessage(data.message);
      setShowToast(true);
      setTimeout(() => navigate('/login'), 3000);
    } catch (err) {
      setError(err.message);
      setShowToast(true);
    }
  };
  

  return (
    <Screen backgroundImage={
      localStorage.getItem('backgroundPicture') && localStorage.getItem('backgroundPicture').includes('http')
        ? localStorage.getItem('backgroundPicture')
        : require('./../../assets/images/login.png')
    }>
      <div className="center">
        <form onSubmit={handleRegister}>
          <Card style={{ width: '18rem', opacity: '0.9' }}>
            <Title>{t("register")}</Title>
            <div className="row g-2">
              <InputField
                mb="2"
                placeholder={t("firstName")}
                className="col-6"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
              <InputField
                mb="2"
                placeholder={t("lastName")}
                className="col-6"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
            <InputField
              mb="2"
              placeholder={t("email")}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <div style={{ position: 'relative' }}>
              <InputField
                mb="2"
                placeholder={t("password")}
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                minLength={MIN_PASSWORD_LENGTH}
                maxLength={MAX_PASSWORD_LENGTH}
              />
              <span
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '30%',
                  cursor: 'pointer'
                }}
              >
                {showPassword ? <Icon name="eye-crossed" theme="primary" /> : <Icon name="eye" theme="primary" />}
              </span>
            </div>

            <div style={{ position: 'relative' }}>
              <InputField
                mb="3"
                placeholder={t("confirmPassword")}
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                minLength={MIN_PASSWORD_LENGTH}
                maxLength={MAX_PASSWORD_LENGTH}
              />
              <span
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '30%',
                  cursor: 'pointer'
                }}
              >
                {showConfirmPassword ? <Icon name="eye-crossed" theme="primary" /> : <Icon name="eye" theme="primary" />}
              </span>
            </div>

            <Button type="submit">{t("register")}</Button>
            <div className="mt-2">
              <Link to='/login' className="link-active">
                {t("alreadyHaveAccount")}
              </Link>
            </div>

            <Toast
              showToast={showToast}
              setShowToast={setShowToast}
              message={successMessage ? t(successMessage) : t(error)}
              icon={successMessage ? "check-circle" : "exclamation-circle"}
            />
          </Card>
        </form>
      </div>
    </Screen>
  );
};

export default RegisterPage;
