import React from 'react';
import Base from '../Base';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { fetchUserInfo } from '../../utils';
import Card from '../../components/elements/Card';
import Icon from '../../components/common/Icon';
import Back from '../../components/common/Back';
import Image from '../../components/images/Image';
import Center from '../../components/css/Center';

const Page = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserInfo('/planning', setUserInfo, setLoading, setError, []);
  }, []);

  const getEventStatus = (startTime, endTime, userDate) => {
    const now = new Date().toTimeString().slice(0, 5);
    const today = new Date().toISOString().split('T')[0]; // Gets the date in "YYYY-MM-DD" format
  
    if (userDate !== today) return "not_today"; // If the event is not today, return "not_today"
    if (now >= startTime && now < endTime) return "ongoing";
    if (now > endTime) return "past";
    return "upcoming";
  };
  
  

  useEffect(() => {
    if (!loading && userInfo?.planning?.length > 0) {
      // Find the first element with the "btn-primary" class
      const firstBtnPrimary = document.querySelector('.btn-primary');
      if (firstBtnPrimary) {
        firstBtnPrimary.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [loading, userInfo]);  
  


  return (
    <Base activeNav="home">
      
        <Card className='outline-primary'>
          <Back to='/home' />
          <Title>
            <Icon name="calendar-lines" className="me-2" theme="primary" style={{'fontSize': '30px'}} />
            {t('planning')}
          </Title>
        </Card>

        {userInfo?.planning?.length > 0 ? (
            <>
            {userInfo && userInfo.planning && userInfo.planning.map((activity, index) => {
            const isActivityOngoing = getEventStatus(activity.start_time, activity.end_time, activity.date) === "ongoing";
            const activityIsPast = getEventStatus(activity.start_time, activity.end_time, activity.date) === "past";

            return (
              <Card 
                key={index}
                p="3" 
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} 
                className={`
                  mb-1 justify-content-between d-flex align-items-center 
                  ${isActivityOngoing ? 'btn-primary' : ''}
                  ${activityIsPast ? 'inactive' : ''}
                `}
                to={activity.is_challenge ? "/score" : undefined} 
                onClick={activity.is_challenge ? () => sessionStorage.setItem('selectedScoreId', activity.id) : undefined}
              >
                <div className='text-start'>
                  {activity.name}
                  <div className='text-secondary'>
                    <Icon name="clock" className="me-1" theme="primary" />
                    {activity.start_time}
                  </div>
                </div>
                
                {activity.is_challenge && <b><Icon name="angle-right" /></b>}
              </Card>
            );
          })}
          </>
        ) : (
          <Card className="mt-1" p='3'>
            <Center><Image url={require('./../../assets/images/not-found.png')} className='my-4' /></Center>
            <Title heading='6' my="0">{t('noScheduleFound')}</Title>
          </Card>
        )}
        
    </Base>
  );
};

export default Page;