import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Toast from '../../components/forms/Toast';
import Screen from '../../components/common/Screen';
import Card from '../../components/elements/Card';
import Title from '../../components/common/Title';

const ConfirmEmailPage = () => {
  const { t } = useTranslation();
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const confirmEmail = async () => {

      const confirmation_token = sessionStorage.getItem('confirmation_token')
      try {
        const response = await fetch('/api/confirm-email', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json'},
          body: JSON.stringify(confirmation_token)
        });

        if (response.ok) {
          const data = await response.json();
          setMessage(data.message);
          setShowToast(true);
          // Redirect after 5 seconds
          setTimeout(() => navigate('/login'), 5000);
        } else {
          const errorData = await response.json();
          throw new Error(errorData.message || 'somethingWentWrong');
        }
      } catch (err) {
        setError(err.message);
        setShowToast(true);
      }
    };

    confirmEmail();
  }, [location, navigate]);



  return (
    <Screen backgroundImage={
      localStorage.getItem('backgroundPicture') && localStorage.getItem('backgroundPicture').includes('http')
        ? localStorage.getItem('backgroundPicture')
        : require('./../../assets/images/login.png')
    }>
      <div className="center">
        <Card style={{ width: '18rem', opacity: '0.9' }}>
          <Title>{t("confirmEmail")}</Title>
          {!message && !error && t("pleaseWait")}
          {message && <p>{t(message)}</p>}
          {error && <p>{t(error)}</p>}
        </Card>
        <Toast
          showToast={showToast}
          setShowToast={setShowToast}
          message={message ? t(message) : t(error)}
          icon={message ? "check-circle" : "exclamation-circle"}
        />
      </div>
    </Screen>
  );
};

export default ConfirmEmailPage;
