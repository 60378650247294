import React, { useState, useEffect } from 'react';
import Base from '../Base';
import Title from '../../components/common/Title';
import { useTranslation } from 'react-i18next';
import Screen from '../../components/common/Screen';
import Button from '../../components/elements/Button';
import Icon from '../../components/common/Icon';
import Left from '../../components/css/Left';
import Image from '../../components/images/Image';
import { fetchUserInfo, apiRequest } from '../../utils';
import Back from '../../components/common/Back';
import { Link, useNavigate } from 'react-router-dom';
import InputField from '../../components/forms/InputField';
import Modal from '../../components/elements/Modal';
import Toast from '../../components/forms/Toast';

const Page = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [showCreateEmployeeModal, setShowCreateEmployeeModal] = useState(false);

  // Use an endpoint suitable for employees if needed
  useEffect(() => {
    fetchUserInfo('/manage-employees', setUserInfo, setLoading, setError, []);
  }, []);

  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  // Filter the employees based on the search query
  const filteredEmployees = userInfo?.employees?.filter((employee) =>
    employee.full_name.toLowerCase().includes(searchQuery)
  );

  const handleShareInvite = () => {
    const inviteLink = `${window.location.origin}/login?assignedCompanyId=${userInfo?.user_company_id}`;
  
    if (navigator.share) {
      navigator.share({
        title: t("inviteToCompany"),
        text: t("joinMyCompany"),
        url: inviteLink,
      })
      .catch((error) => console.error("Error sharing:", error));
    } else {
      alert(t("sharingNotSupported")); // Fallback for unsupported browsers
    }
  };

  // Controlled form inputs for creating an employee
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [employeeEmail, setEmployeeEmail] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
  
    const payload = {
      email: employeeEmail,
      first_name: firstName,
      last_name: lastName
    };
  
    try {
      await apiRequest('POST', '/create-employee', payload);
      setError(null); 
      setShowCreateEmployeeModal(false);
  
      // Reset form fields after submission
      setFirstName("");
      setLastName("");
      setEmployeeEmail("");
      window.location.reload();
    } catch (error) {
      console.error('Error:', error.message);
      setError(error.message);
      setShowToast(true);
    }
  };

  const removeEmployee = async (userId) => {
    try {
      await apiRequest('POST', '/delete-employee', { user_id: userId });
      setError(null);
      window.location.reload();
    } catch (error) {
      console.error('Error:', error.message);
      setError(error.message);
      setShowToast(true);
    }
  };

  const promoteToAdmin = async (userId) => {
    try {
      await apiRequest('POST', '/promote-employee', { user_id: userId });
      setError(null);
      window.location.reload();
    } catch (error) {
      console.error('Error:', error.message);
      setError(error.message);
      setShowToast(true);
    }
  };
  
  const demoteFromAdmin = async (userId) => {
    try {
      await apiRequest('POST', '/demote-employee', { user_id: userId });
      setError(null);
      window.location.reload();
    } catch (error) {
      console.error('Error:', error.message);
      setError(error.message);
      setShowToast(true);
    }
  };
  

  return (
    <Screen p='3'>
      <div className='top'>   
        <Back to='/company' />
        <Title>{t('manageEmployees')}</Title> 
        
        {userInfo?.role === 'contactPerson' && (
          <Button 
            theme="primary" 
            className="mt-2" 
            onClick={() => setShowCreateEmployeeModal(true)}
            mb='1'
          >
            <Icon name="person-circle-plus" className="mx-2" theme="light" />
            {t("createEmployee")}
          </Button>
        )}

        {/* Modal for creating an employee */}
        <Modal
          show={showCreateEmployeeModal}
          title={t('createEmployee')}
          onClose={() => setShowCreateEmployeeModal(false)}
          onSubmit={onSubmit}
        >
          <InputField
            name="first_name"
            label={t('firstName')}
            placeholder={t('enterFirstName')}
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <InputField
            name="last_name"
            label={t('lastName')}
            placeholder={t('enterLastName')}
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <InputField
            name="email"
            label={t('employeeEmail')}
            placeholder={t('enterEmployeeEmail')}
            type="email"
            value={employeeEmail}
            onChange={(e) => setEmployeeEmail(e.target.value)}
          />
        </Modal>

        {/* Toast Notification for errors */}
        <Toast showToast={showToast} setShowToast={setShowToast} message={t(error)} />

        {userInfo && (
          <Button theme="secondary" className="mt-1" mb='2' onClick={handleShareInvite}>
            <Icon name="share-square" className="mx-2" theme='light' />
            {t("inviteToCompany")}
          </Button>
        )}

        {(userInfo?.admins?.length > 0 || filteredEmployees?.length > 0) && (
          <div className='justify-content-center'>
            <hr className="mb-2" />

            {/* Admins Section (no search) */}
            {userInfo?.admins?.length > 0 && (
              <>
                <Left>
                  <Title heading='6' my='2' className='mt-2 mb-3'>
                    {t('admins')} ({userInfo.admins.length})
                  </Title>
                </Left>
                {userInfo.admins.map((admin) => (
                  <div className="dropdown" key={admin.id}>
                    <Button p='1' mb="1" theme='light' dataBsToggle="dropdown" ariaExpanded={false}>
                      <div className='row'>
                        <div className='col-3 d-flex align-items-center'>
                          <Image url={admin.photo_url || require('./../../assets/images/fallback/user.png')} size='3rem' className='circle mb-1' reload />
                        </div>
                        <div className='col-8 d-flex flex-column justify-content-center' style={{ marginLeft: '-1.5rem' }}>
                          <div className='d-flex align-items-center flex-wrap'>
                            <b className='me-2'>{admin.full_name}</b>
                            {admin.role?.toLowerCase() === 'contactpersoon' && (
                              <i className='text-secondary'>({t("contactPerson")})</i>
                            )}
                          </div>
                          <div>
                            <p className='text-secondary mb-0 text-start text-break'>{admin.email}</p>
                          </div>
                        </div>

                        <div className='col-1 d-flex align-items-center'>
                          <Icon name='menu-dots-vertical' theme='secondary' style={{ marginLeft: '-0.8rem' }} />
                        </div>
                      </div>
                    </Button>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <Link className="dropdown-item" to='/public-profile' onClick={() => sessionStorage.setItem('otherUserId', admin.id)}>
                          <Icon name='user' className='me-1' />
                          {t("viewProfile")}
                        </Link>
                      </li>
                      {admin.role?.toLowerCase() !== 'contactpersoon' && (
                        <li>
                          <Link className="dropdown-item" onClick={() => demoteFromAdmin(admin.id)}>
                            <Icon name='arrow-circle-down' className='me-1' />
                            {t("demoteFromAdmin")}
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                ))}
              </>
            )}

            {/* Employees Section (searchable) */}
            {userInfo?.employees?.length > 0 && (
              <>
                <hr className="mb-2" />
                <Left>
                  <Title heading='6' my='2' className='mt-2 mb-3'>
                    {t('employees')} ({filteredEmployees.length})
                  </Title>
                </Left>

                <div className='row align-items-center'>
                  <div className='col-1' style={{ marginTop: '-0.7rem' }}>
                    <Icon name="search" />
                  </div>
                  <div className='col-11'>
                    <InputField 
                      placeholder={t('searchEmployees')} 
                      name='searchEmployees'
                      value={searchQuery}
                      onChange={handleSearchChange}
                    >
                      <Icon name="employees" />
                    </InputField>
                  </div>
                </div>

                {filteredEmployees.map((employee) => (
                  <div className="dropdown" key={employee.id}>
                    <Button p='1' mb="1" theme='light' dataBsToggle="dropdown" ariaExpanded={false}>
                      <div className='row'>
                        <div className='col-3 d-flex align-items-center'>
                          <Image url={employee.photo_url || require('./../../assets/images/fallback/user.png')} size='3rem' className='circle mb-1' reload />
                        </div>
                        <div className='col-8 d-flex flex-column justify-content-center text-start' style={{ marginLeft: '-1.5rem' }}>
                          <b>{employee.full_name}</b>
                          <p className='text-secondary mb-0 text-break'>{employee.email}</p>
                        </div>

                        <div className='col-1 d-flex align-items-center'>
                          <Icon name='menu-dots-vertical' theme='secondary' style={{ marginLeft: '-0.8rem' }} />
                        </div>
                      </div>
                    </Button>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <Link className="dropdown-item" to='/public-profile' onClick={() => sessionStorage.setItem('otherUserId', employee.id)}>
                          <Icon name='user' className='me-1' />
                          {t("viewProfile")}
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" onClick={() => promoteToAdmin(employee.id)}>
                          <Icon name='arrow-circle-up' className='me-1' />
                          {t("promoteToAdmin")}
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" onClick={() => removeEmployee(employee.id)}>
                          <Icon name='cross' className='me-1' />
                          {t("removeEmployee")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                ))}
              </>
            )}
          </div>
        )}

      </div>

      <div className='bottom'>
        <div className="px-3 pt-3" style={{ backgroundColor: 'white' }}>
          <Button to="/company" className='mb-3'>
            {t("done")}
          </Button>
        </div>
      </div>
    </Screen>
  );
};

export default Page;
